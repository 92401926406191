
import Vue from 'vue';

export default Vue.extend({
  name: 'SocialMediaElement',
  props: {
    headline: Array,
    icons: Array,
    classes: String,
  },
});
